<template>
  <div>
    <!-- Show the container -->
    <div
      class="post-item d-sm-flex pa-3"
      @click="redirectToWeb(post.url)"
    >
      <!-- Show the image -->
      <div class="post-item__image">
        <!-- Show the backdrop -->
        <div class="post-item__image__backdrop" :style="{ 'background-image': `url(${proxyUrl(post.thumbnail)})` }"></div>

        <!-- Show the thumbnail here -->
        <img
          :src="proxyUrl(post.thumbnail)"
          crossorigin="anonymous"
        />
      </div>

      <!-- Show the content -->
      <div class="pt-4 pt-sm-0 pl-sm-4 d-flex flex-column">
        <!-- Show the post user -->
        <profile-chip
          :platform="post.platform"
          :data="preview || post"
        />

        <!-- Show the caption -->
        <div
          v-if="post.caption"
          class="mt-3 text-subtitle-2 font-weight-light post-item__caption"
          :title="post.caption"
        >
          <slot name="caption">
            <pre v-text="post.caption"></pre>
          </slot>
        </div>

        <!-- Show a v-spacer -->
        <v-spacer />

        <!-- Show the date -->
        <div class="mt-2 text-subtitle-2 font-weight-light">
          {{ dayjs(post.posted_at).format("LLL") }}
        </div>

        <!-- Show the stats -->
        <div class="d-flex align-center mt-3 text-subtitle-2 font-weight-normal white-space-nowrap">
          <div
            v-if="post.likes"
            class="d-flex align-center mr-3"
            :title="post.likes + ' likes'"
          >
            <v-icon
              size="16"
              :color="isThemeLight ? 'black' : null"
            >thumb_up</v-icon> &nbsp;
            {{ nFormatter(post.likes) }}
          </div>

          <div
            v-if="post.views"
            class="d-flex align-center mr-3"
            :title="post.views + ' views'"
          >
            <v-icon
              size="16"
              :color="isThemeLight ? 'black' : null"
            >play_circle</v-icon> &nbsp;
            {{ nFormatter(post.views) }}
          </div>

          <div
            v-if="post.comments"
            class="d-flex align-center mr-3"
            :title="post.comments + ' comments'"
          >
            <v-icon
              size="16"
              :color="isThemeLight ? 'black' : null"
            >comment_bank</v-icon> &nbsp;
            {{ nFormatter(post.comments) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Export the SFC
export default {
  // Name of the component
  name: "PostItem",

  // Register children components
  components: {
    ProfileChip
  },

  // Accept incoming data from parent
  props: {
    post: {
      type: Object,
      required: true
    },

    preview: {
      type: Object,
      required: false
    }
  },
}
</script>

<style lang="stylus" scoped>
.post-item
  width 100%
  border 1px solid #e0e0e0
  border-radius 8px

  &__image
    width 100%
    height 300px
    border-radius 8px
    overflow hidden
    position relative
    flex-grow 1

    &__backdrop
      width 100%
      height 100%
      background-size cover
      background-position center
      filter blur(5px) grayscale(0.4) opacity(0.8)

    img
      width 100%
      height 100%
      object-fit contain
      object-position center
      position absolute
      top 0
      left 0
      z-index 1

  &__caption
    overflow-y auto
    max-height 186px

    pre
      width 100%
      font-family inherit
      white-space pre-wrap
      word-wrap break-word

  @media screen and (min-width 600px)
    &__image
      width 280px
      min-width 280px
      max-width 280px

    .flex-column
      max-width calc(100% - 280px)

  @media screen and (min-width 960px)
    &__image
      width 300px
      min-width 300px
      max-width 300px

    .flex-column
      max-width calc(100% - 300px)

  @media screen and (min-width 1264px)
    &__image
      width 240px
      min-width 240px
      max-width 240px

    .flex-column
      max-width calc(100% - 240px)
</style>
